body, html {
  font-family: "Arial", monospace;
  background-color: black;
  background-image: linear-gradient(#E8EAF6, #FFFFFF, #E8EAF6);
  text-align: center;
  color: black;
  -webkit-touch-callout: none;
-webkit-user-select: none;
 -khtml-user-select: none;
   -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
.wavetop{
    background-image: url('srcimg/wavetop.svg');
    background-repeat: no-repeat;
    display: inline-block;
    bottom: 0;
    left: 0;
    background-position: center bottom;
    width: 100%;
}

.wavebottom{
    background-image: url('srcimg/wavebottom.svg');
    background-repeat: no-repeat;
    display: inline-block;
    bottom: 0;
    left: 0;
    width: 100%;
}


.secondwavetop{
    background-image: url('srcimg/secondwavetop.svg');
    background-repeat: no-repeat;
    display: inline-block;
    bottom: 0;
    left: 0;
    background-position: center bottom;
    width: 100%;
}

.secondwavebottom{
    background-image: url('srcimg/secondwavebottom.svg');
    background-repeat: no-repeat;
    display: inline-block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: -3px;

}


.contackwithus{
  font-weight : bold;
  font-size: 25px;
  margin-top: 3%;
}

.contackwithus_smalltext{
  font-size: 19px;
}

.sloganxl{
  font-family: "Times New Roman", monospace;
  font-weight: bold;
  text-align: center;
  font-size: 22px;
  padding-top: 1em;
}

.sloganm{
  font-family: "Times New Roman", monospace;
  text-align: left;
  font-size: 1.7em;
}


.slogans{
  text-align: center;
  font-size: 19px;
}


.social{
  border-radius: 15px 0px 0px 15px;
  background-color: #F5F5F5;
  z-index: 300;
  position:fixed;
  bottom:0;
  right:0;
  margin-bottom: 1em;
  padding-right: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  text-align: right;
}

.socialicon{
  height: 50px;
  width: 50px;
}

.socialmobilestatus{
  visibility: hidden;
}



.socialmobile{
  background-color: #F5F5F5;
  z-index: 301;
  position:fixed;
  bottom:0;
  left:0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
}

.socialmobileicon{
  height: 30px;
  width: 30px;
}

.socialmobileiconhead{
  height: 50px;
  width: 50px;
}

.socialHeadButton{
  width: 50%;
  margin-bottom: 0.5em;
}



.socialHeadButton{
  width: 40%;
	text-align: center;
	padding: 5px 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #0288D1;
	color: #000000;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.socialHeadButton:not(:hover){
	color: #000000;
  background-color: transparent !important;
}
.socialHeadButton:hover {
	background: #B3E5FC;
}
.socialHeadButton a{
	color: #000000;
}
.socialHeadButtonWhatsapp{
  width: 40%;
	text-align: center;
	padding: 5px 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #388E3C;
	color: #000000;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.socialHeadButtonWhatsapp:not(:hover){
	color: #000000;
  background-color: transparent !important;
}
.socialHeadButtonWhatsapp:hover {
  	background: #C8E6C9;
}

.socialmobileiconhead{
  height: 50px;
  width: 50px;
}

.socialslogan{
  padding-top: 4%;
  text-align: center;
  font-size: 25px;
}
.colright{
  text-align: right;
}
.colleft{
    text-align: left;
}

div{
  color: black;
  font-family: "Arial", monospace;
  font-style: normal;
  font-variant:normal;
  z-index: 1;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

img{
  user-drag: none;
   user-select: none;
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}


.sik{
  z-index:2;
  position: fixed;
}


.sok{
  z-index:1;
  position:absolute;
}




.menu{
  background-color: white;
  border-radius: 0px 0px 15px 15px;
  height: 4em;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 2px #E0E0E0;
}

.menulogopart{
  background-color: white;
  border-radius: 0px 0px 15px 0px;
  height: 15em;
  box-shadow: 1px 1px 2px #E0E0E0;
}

.menulogo{
    width: 70%;
}


.menuitem{
  height: 100%;
  line-height:400%;
  text-align: center;
}

.menuitemlink {
  color: black;
  text-decoration: none;
}

.menuitemlink:hover{
  color: black;
}



.logopart{
  width: 100%;
  padding-top: 5%;
  position: absolute;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


/*




.company-slogan{
  height: 20%;
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}




*/

/*
 */


.logo {
  width: 20%;
}
.namelogo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}






.headlanguage{
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: black;
  border-radius: 0px 0px 15px 15px;
}


.dropbtnsol {
  background-color: #D32F2F;
  color: white;
  font-size: auto;
  border: none;
  position: absolute;
  left: 0;
  margin-top: 2.1em;
  cursor: pointer;
  width: 12em;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  border-radius: 0px 0px 15px 0px;
}

/* dropdown menu languase */
.dropbtn {
  background-color: #1A237E;
  color: white;
  font-size: auto;
  border: none;
  cursor: pointer;
  margin-top: 2.1em;
  color: white;
  width: 7em;
  z-index: 4;
  position: relative;
  display: inline-block;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  border-radius: 0px 0px 15px 15px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  overflow: hidden;
  z-index: 1;
  right: 0;
  width: 7em;
  border-radius: 0px 0px 15px 15px;
  background-size: cover;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  text-decoration: none;
  display: block;
}

.dropdown a {
  color: black;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-size: cover;
  background-color: #3F51B5;
  color: white;
}

.menu_position{
  /* margin-right: 10%; */
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #1A237E;
  color: white;
  border-radius: 0px 0px 0px 0px;
}
/* dropdown menu languase */







.langbut{
  background-color: black;
  color: white;
  width: 33%;
  border: 1px solid black;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  border-radius: 0px 0px 15px 15px;
}

.service-name{
  text-align: left;
  font-weight: bold;
}


.services{
    text-align: left;
}



.service-desing{
  text-align: left;
}

.form-input{
  padding: 0.5em;
  width: 100%;
  background-color: white;
  color: black;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid white;
  border-radius: 4px;
  box-sizing: border-box;
  border-radius: 15px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray;
  opacity: 1; /* Firefox */
}

::selection{
  background: #9FA8DA;
}

input {
  outline-color: black;
}
input:focus {
  outline:0 !important;
}

.needtext{
  text-align: left;
  font-size: 24px;
  line-height: 1;
}

.doctor_div_space{
  padding-bottom: 2em;
}

.mybutton{
  padding: 0.5em;
  background-color: #283593;
  color: white;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  border-radius: 15px;
}

.and_other_doctor{
  font-weight: bold;
  font-size: 17px;
  color: white;
}


.and_other_hospital{
  font-weight: bold;
  font-size: 17px;
  padding-top: 2em;
}

button:focus {
  outline:0 !important;
}

.icons{
  width : 50px;
  height : 50px;
  float:left;
  margin-bottom: 0.7em;
}
.miniicons{
  width : 14px;
  height : 14px;
  margin-right: 0.5em;
}

.spicons{
  width : 100%;
  height : 100%;
  padding: 1em;

    border: 3px solid green;
}

.spheadtext{
  margin: auto;
 width: 100%;
 height: 100%;
 border: 3px solid green;

 padding: 30px 0;
}

.headicons{
  width : 80px;
  height : 80px;
  float: center;
  margin-bottom: 0.7em;
}

.company-slogan {
  font-family:  "Courier New Bold", monospace;
  font-size: 3vw;
  text-align: left;
}

.slogan-description{
  font-family:  "Courier New Bold", monospace;
  font-size: 2vw;
  text-align: left;
}


.logopart{
  position:relative;
  display:inline-block;
  text-align: center;
}

.formclass{
  line-height: inherit;
}

.dirsection{
  text-align: left;
  margin-top: 3em;
  margin-bottom: 3em;
  font-size: 20px;
  font-weight: bold;
}

.dirsectiondr{
  text-align: left;
  margin-bottom: 3em;
  font-size: 20px;
  font-weight: bold;
  color: white;
}


.cityname{
  text-align: left;
  margin-left: 0.5em;
  font-weight: bold;
}




.dirsectionsp{
  text-align: left;
  padding-left: 1.5em;
  margin-bottom: 3em;
  font-size: 20px;
  font-weight: bold;
}

.dirsectioncontact{
  text-align: left;
  padding-top: 3em;
  font-size: 18px;
  color: white;
}


.contactheads{
  color: white;
  font-size: 17px;
  padding-top: 2em;


}

.spartnersname{
  margin-bottom: 2.4em;
  margin-left: 1.8em;
  margin-right: 1.8em;
  text-align: left;
  /* -webkit-filter: drop-shadow(2px 2px 2px #000000);
  filter: drop-shadow(0px 0px 50px #000000); */
}

.contacts{
  text-align: left;
  margin-bottom: 2em;
  margin-left: 1.8em;
  margin-right: 1.8em;
}
.contacts-item{
  margin-bottom: 1em;
  color: white;
}
.contacts-item-phone{
  color: white;
  margin-bottom: 1em;
  white-space: nowrap;
}

.contactback{
  color: white;
  font-size: 14px;
  text-align: center;
  background-image: linear-gradient(#3F51B5, #303F9F, #1A237E);
}

.footer{
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  color: white;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: #1A237E;
  /* border-radius: 15px 15px 0px 0px; */
}

.headfooter {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  color: white;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: #1A237E;
  /* border-radius: 15px 15px 0px 0px; */
}


.headfooter div {
  color: white;
}



.serviceprocessback{
  margin-top: -0.3em;
  margin-bottom: -0.3em;
  background-image: linear-gradient(#3F51B5, #303F9F, #1A237E);
}

.serviceprocessback div{
  color: white;
}

.distance{
  padding-top: 1%;
  margin-bottom: 1%;
}

.distancelogo{
  /* margin-bottom: 25%; */
}

.distancehead{
    /* margin-top: 10%; */
}

.distanceHeadsocial{
  margin-bottom: 5%;
}

.distancehead{
  margin-bottom: 5%;
}
.distaceservice{
  padding-top: 5%;
  padding-bottom: 5%;
}



.distancehospital{
  padding-top: 5%;
  margin-bottom: 5%;
}

.doctorsbg{
  margin-top: -0.3em;
  margin-bottom: -1em;
  background-image: linear-gradient(#3F51B5, #303F9F, #1A237E);
}

.distancecontact{
  padding-bottom: 2%;
}

.formdistance{
  padding-top: 1%;
  margin-bottom: 1%;
}


.hosspace{
  margin-bottom: 2em;
}


.docktorsback{
  background-color: #E8EAF6;
  height: 100%;
  width: 100%;
  border-radius: 30px 30px 30px 30px;
}


.hospitalsback{
  background-color: #E8EAF6;
  height: 9em;
  width: 100%;
  float: center;
  border-radius: 30px 30px 30px 30px;
}

.texthospital{
  margin-left: 0.7em;
  margin-right: 0.7em;

}

.hicons{
  margin: auto;
  width : 80%;
  height : 50%;
  float:left;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  text-align: center;
}

.doctor_photo{
  width : 100%;
  height : 90%;
  margin-top: 0.6em;
  border-radius: 25px 25px 25px 25px;
}

.contacticons{
  width : 20px;
  height : 20px;
  margin-right: 0.2em;
  float:left;
}
.tursab{
  width : 180px;
  height : 70px;
  background-color: white;
  padding: 0.7em;
  margin-bottom: 1em;
  margin-right: 1em;
  border-radius: 10px 10px 10px 10px;

}

.sagb{
  width : 180px;
  height : 70px;
  background-color: white;
  padding: 0.4em;
  margin-bottom: 1em;
  margin-right: 1em;
  border-radius: 10px 10px 10px 10px;

}

.aboutbutton{
  float: left;

  background-color: black;
  color: white;
  font-size: auto;
  border: none;
  cursor: pointer;
  color: white;
  width: 10em;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  border-radius: 0px 0px 15px 15px;
}

.logoimg{
  height: 250px;
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.error{
  text-align: center;
 font-family: 'Times New Roman';
 font-size: 5.5vw;
 margin-top: 3em;
}

.backerror{
  min-height: 100vh;
  padding: 0;
}


@media only screen and (max-width: 1024px) {

  .logo {
    width: 30%;
  }

  .namelogo{
    width: 50%;
  }

  .socialhead{
    height: 3em;
    width: 3em;
  }

  .social{
    visibility: hidden;
  }
  .socialmobilestatus{
    visibility: visible;
  }

  .slogans{
    margin-bottom: 5%;
  }

  div.colright{
    width: 100%;
  }
  div.colleft{
    width: 100%;
  }

  .socialmobileiconhead{
    height: 25px;
    width: 25px;
  }

  .colright{
    text-align: center;
  }

  .colleft{
    text-align: center;
  }

  .socialslogan{
    font-size: 20px;
  }

  .colright{
    text-align: center;
  }

  .colleft{
      text-align: center;
    }


    .contackwithus_smalltext{
      font-size: 17px;
    }


    .hicons{
      margin: auto;
      width : 70%;
      height : 45%;
      float:center;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      margin-left: 4em;
      margin-right: 1em;
      text-align: center;
    }


}


@media only screen and (max-width: 1400px) {





  div.col-md-2.servicesection{
    width: 25%;
  }

}


@media only screen and (max-width: 1024px) {

  div.col-md-4.servicesection{
    width: 50%;
  }

  .logo {
    width: 30%;
  }

  .namelogo{
    width: 50%;
  }

  .dirsectionsp{
    padding-top: 2em;
  }

  /* .dirsection{
    text-align: center;
  } */

  .sloganxl{
    text-align: center;
    font-size: 20px;
  }

  .socialHeadButton{
   width: 60%;
 }

 .socialHeadButtonWhatsapp{
   width: 60%;
 }

 .socialslogan{
   font-size: 20px;
 }

 .colright{
   text-align: center;
 }

 .colleft{
     text-align: center;
   }


   .hicons{
     margin: auto;
     width : 100%;
     height : 50%;
     float:center;
     margin-bottom: 0.5em;
     margin-top: 0.5em;
     margin-right: 1em;
     text-align: center;
   }


   .footer{
     padding-bottom: 5em;
   }

}



@media only screen and (max-width: 991px) {
  div.col-md-4.servicesection{
    width: 100%;
  }




  div.col-md-2.servicesection{
    width: 33%;
  }

  .logo {
    width: 40%;
  }

  .namelogo{
    width: 80%;
  }

  .dirsectionsp{
    padding-top: 2em;
  }

  .formdistance{
    padding-top: 3em;
    margin-bottom: 1em;
  }

  .sloganxl{
    text-align: center;
    font-size: 20px;
    font-style: bold;
    margin-bottom: 0.5em;
  }

  .slogans{
    text-align: center;
    font-size: 17px;
  }

  .colright{
    text-align: center;
  }

  .colleft{
      text-align: center;
    }

    .hicons{
      margin: auto;
      width : 100%;
      height : 30%;
      float:center;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      text-align: center;
    }


    .hospitalsback{
      background-color: #E8EAF6;
      height: 7em;
      width: 100%;
      float: center;
      border-radius: 30px 30px 30px 30px;
    }


}

@media only screen and (max-width: 767px) {
  div.col-md-4.servicesection{
    width: 100%;
  }

  div.col-md-2.servicesection{
    width: 50%;
  }

  .logo {
    width: 45%;
  }

  .namelogo{
    width: 80%;
  }

  .sloganxl{
    text-align: center;
    font-size: 20px;
    font-style: bold;
    margin-bottom: 0.5em;
  }

  .slogans{
    text-align: center;
    font-size: 17px;
  }

  .colright{
    text-align: center;
  }

  .colleft{
      text-align: center;
    }



    .hicons{
      margin: auto;
      width : 60%;
      height : 40%;
      float:center;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      text-align: center;
    }


    .hospitalsback{
      background-color: #E8EAF6;
      height: 10em;
      width: 100%;
      float: center;
      border-radius: 30px 30px 30px 30px;
    }

}

@media only screen and (max-width: 375px) {
  .company-slogan {
    font-family: "Courier New Bold", monospace;
    font-size: 19px;
  }

  div.col-md-2.servicesection{
    width: 100%;
  }

  .slogan-description{
    font-family: "Courier New Bold", monospace;
    font-size: 20px;
  }

  .logoimg{
    height: 150px;
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    height: 50%;
    width: 50%;
  }

  .socialHeadButton{
     width: 100%;
   }

   .socialHeadButtonWhatsapp{
     width: 100%;
   }

   .socialslogan{
     font-size: 17px;
   }

   .colright{
     text-align: center;
   }

   .colleft{
       text-align: center;
    }


    .hicons{
      margin: auto;
      width : 70%;
      height : 45%;
      float:center;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      margin-left: 4em;
      margin-right: 1em;
      text-align: center;
    }



}


/*



@media only screen and (max-width: 1025px) {

  .logopart{
    width: 100%;
    padding-top: 20%;
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }


  .logo {
    height: 50%;
    width: 50%;
  }

  .namelogo{
    height: 40%;
    width: 90%;
  }

  .company-slogan{
    height: 20%;
    width: 80%;
  }

}


@media only screen and (max-width: 1000px) {


  .logopart{
    width: 100%;
    position: absolute;
  }



  .namelogo{
    height: 50%;
    width: 80%;
  }

  .company-slogan{
    height: 20%;
    width: 90%;
  }

}

@media only screen and (max-width: 700px) {

  .logopart{
    width: 100%;
    padding-top: 20%;
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }




  .namelogo{
    height: 40%;
    width: 70%;
  }

  .company-slogan{
    height: 20%;
    width: 90%;

  }

}

@media only screen and (max-width: 322px) {


  .logopart{
    width: 100%;
    position: absolute;
    top: 5%;
  }


  .namelogo{
    height: 50%;
    width: 80%;
  }

  .company-slogan{
    height: 20%;
    padding-top: 3%;
    width: 90%;
  }
} */
